import React from "react"
import { Link } from "gatsby"

const MainNav = ({ active }) => {
  return (
    <>
      <nav className="nav">
        <ul className="nav__list--left list-unstyled">
          <li className="nav__list--left--item">
            <Link
              className={` ${
                active === "home"
                  ? "nav__list--left--link--active"
                  : "nav__list--left--link"
              }`}
              to="/"
            >
              Home
            </Link>
          </li>
          <li className="nav__list--left--item">
            <Link
              className={` ${
                active === "about"
                  ? "nav__list--left--link--active"
                  : "nav__list--left--link"
              }`}
              to="/about"
            >
              Why Us
            </Link>
          </li>

          <li className="nav__list--left--item">
            <Link
              className={` ${
                active === "portfolio"
                  ? "nav__list--left--link--active"
                  : "nav__list--left--link"
              }`}
              to="/portfolio"
            >
              Case Study
            </Link>
          </li>
          <li className="nav__list--left--item">
            <span
              className={` ${
                active === "services"
                  ? "nav__list--left--link--active"
                  : "nav__list--left--link"
              }`}
            >
              Services
            </span>
            <ul className="nav__list--left--item--sublist">
              <li className="nav__list--left--item--subItem">
                <Link
                  className="nav__list--left--item--subLink"
                  to="/webdesign-philippines"
                >
                  Web Design & Development
                </Link>
              </li>
              <li className="nav__list--left--item--subItem">
                <Link
                  className="nav__list--left--item--subLink"
                  to="/seo-philippines"
                >
                  SEO (Google Ranking)
                </Link>
              </li>
              <li className="nav__list--left--item--subItem">
                <Link
                  className="nav__list--left--item--subLink"
                  to="/google-reviews"
                >
                  SEO (Google Reviews)
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav__list--left--item">
            <Link
              className={` ${
                active === "blog"
                  ? "nav__list--left--link--active"
                  : "nav__list--left--link"
              }`}
              to="/blog"
            >
              Blog
            </Link>
          </li>
        </ul>

        <ul className="nav__list--right list-unstyled">
          <li>
            <Link className="nav__list--right--link" to="/contact">
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default MainNav
