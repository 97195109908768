import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
// import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const HeaderFlat = ({ heading, subheading, contact, background }) => {
  //   const customStyle = {
  //     backgroundImage: "url(" + background + ")",
  //     backgroundRepeat: "no-repeat",
  //   }

  return (
    <header className="header-flat container">
      <div className="header-flat__wrapper  ">
        <div className="nav__right--icon">
          <i className="fas fa-align-right"></i>
        </div>
      </div>
    </header>
  )
}

HeaderFlat.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderFlat.defaultProps = {
  siteTitle: ``,
}

export default HeaderFlat
