import React from "react"
import { Link } from "gatsby"

const BookStrategyBtnPrimary = () => {
  return (
    <>
      <div className="strategy-btn-primary">
        <Link className="strategy-btn__link" to="/strategy-call">
          Book strategy call -{" "}
          <span className="strategy-btn-primary__span"> It's Free</span>{" "}
        </Link>
      </div>
    </>
  )
}

export default BookStrategyBtnPrimary
