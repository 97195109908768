import React, { useState } from "react"
import { Link, graphql } from "gatsby"
// import { openPopupWidget } from "react-calendly"

import HeaderFlat from "../components/HeaderFlat"
import BookStrategyBtnPrimary from "../components/BookStrategyBtnPrimary"
import FooterGlobal from "../components/FooterGlobal"
import SEO from "../components/seo"
import Layout from "../components/layout"
import MainNav from "../components/MainNav"

import { FaPlus } from "react-icons/fa"
import { FaMinus } from "react-icons/fa"

export const getData = graphql`
  {
    wpgraphql_greatweb {
      pageBy(uri: "seo-philippines") {
        pageSiteMetaDescription_acf {
          siteMetaDescription
        }
      }
      homePageBy: pageBy(uri: "home") {
        home_pagesection_acf {
          sections {
            generalSettings {
              announcement
              countdownData
              companyAddress
              companyContactNumber
              companyOverview
              facebookLink
              linkedinLink
              supportEmail
              twiiterLink
            }
          }
        }
      }
    }
  }
`

const SEOPhilippines = ({ data }) => {
  const {
    wpgraphql_greatweb: {
      pageBy: {
        pageSiteMetaDescription_acf: { siteMetaDescription },
      },
      homePageBy: {
        home_pagesection_acf: {
          sections: {
            generalSettings: {
              announcement,
              countdownData,
              companyAddress,
              companyContactNumber,
              companyOverview,
              facebookLink,
              linkedinLink,
              supportEmail,
              twiiterLink,
            },
          },
        },
      },
    },
  } = data

  const [show, setShow] = useState(0)

  const handleAccordion = id => {
    if (show === id) {
      setShow(0)
    } else {
      setShow(id)
    }
  }

  return (
    <>
      <Layout
        announcement={!announcement ? "" : announcement}
        countdownData={!countdownData ? "" : countdownData}
      >
        <SEO
          title={`Frequently Ask Questions | Great Web`}
          description={siteMetaDescription}
        />
        <MainNav />
        <HeaderFlat
        // siteTitle={data.site.siteMetadata?.title || `GreatWeb`}

        // background={heroBG}
        // contact={contactNumber}
        />

        <section className="seo-philippines">
          <div className="seo-philippines__faq container">
            <h3 className="seo-philippines__faq--heading">
              Investment Options
            </h3>
            <h3 className="seo-philippines__faq--heading">
              Frequently Asked Questions
            </h3>
            <p className="seo-philippines__cover--subheading text-center">
              Both investment options require a minimum 6 months. This is the
              best way to guarantee results for your business! After that,
              services will continue on a month-to-month basis.
            </p>
            <ul className="seo-philippines__faq--list">
              <li className="seo-philippines__faq--item">
                <h4
                  className="seo-philippines__faq--item--question"
                  onPointerDown={() => handleAccordion(1)}
                >
                  <div>
                    {show === 1 ? (
                      <FaMinus className="seo-philippines__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="seo-philippines__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="seo-philippines__faq--item--question--span">
                    What happens after the first 6 months?
                  </span>
                </h4>
                {/* {show === 1 && ( */}
                <div
                  className={`${
                    show === 1
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="seo-philippines__faq--item--description">
                    After the first 6 months, we then evaluate where we started
                    and where we are now. We will review if there has been an
                    increase in phone calls as according to the guarantee. We
                    will look at the improvements made, what may need to be done
                    to continue moving forward or look into potentially adding
                    additional keywords to our strategy. Perhaps you have
                    ancillary services you would like to push at that time or
                    perhaps even expanding our reach beyond your exact city. We
                    will develop a recommended strategy and then the ball is
                    completely in your court as to if you would like to continue
                    working together. Also, just a quick reminder that as long
                    as you are our client, we will not accept on any other
                    competitor in your city.
                  </p>
                </div>
                {/* )} */}
              </li>
              <li className="seo-philippines__faq--item">
                <h4
                  className="seo-philippines__faq--item--question"
                  onPointerDown={() => handleAccordion(2)}
                >
                  <div>
                    {show === 2 ? (
                      <FaMinus className="seo-philippines__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="seo-philippines__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="seo-philippines__faq--item--question--span">
                    Will I still be ranked after 6 months?
                  </span>
                </h4>
                {/* {show === 2 && ( */}
                <div
                  className={`${
                    show === 2
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="seo-philippines__faq--item--description">
                    I have had past clients who are still ranking in the
                    Investment Option 1 and haven't paid me a dime in 6 months.
                    Whereas I have had others for whom their competition noticed
                    that they were being surpassed and began to take action.
                    Unfortunately in these cases, if you are not completing
                    competitor research, maintaining our strategy and surpassing
                    the competitors efforts, then you will be passed up. Also,
                    just a quick reminder that as long as you are our client, we
                    will not accept any other competitor in your city.
                  </p>
                </div>
                {/* )} */}
              </li>
              <li className="seo-philippines__faq--item">
                <h4
                  className="seo-philippines__faq--item--question"
                  onPointerDown={() => handleAccordion(3)}
                >
                  <div>
                    {show === 3 ? (
                      <FaMinus className="seo-philippines__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="seo-philippines__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="seo-philippines__faq--item--question--span">
                    {" "}
                    I'm not sure about this being a monthly subscription? Is
                    there really that much involved each month? What am I really
                    paying for?
                  </span>
                </h4>
                {/* {show === 3 && ( */}
                <div
                  className={`${
                    show === 3
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="seo-philippines__faq--item--description">
                    We have a proprietary systemized and proven process that
                    took years to develop and is being updated almost weekly to
                    keep up with Google changes. The first 3 months is extremely
                    involved while we are getting your business onboarded and is
                    where the majority of that $1,500 cost goes towards. We then
                    work every single month to build upon the progress we are
                    making following our detailed process that we have developed
                    in house.
                  </p>
                  
                </div>
                {/* )} */}
              </li>
              
            </ul>
            <div className="seo-philippines__ready">
              <h4 className="seo-philippines__ready--heading">
                Ready to Get Started?
              </h4>
              <p className="seo-philippines__ready--subheading">
                If you want to learn more about how I can help grow your
                business, click below to schedule a free growth call. If you are
                ready to start right away,{" "}
                <Link className="seo-philippines__ready--link" to="/contact">
                  click here to contact me.
                </Link>
              </p>
              {/* 
              <button
                className="mt-3 btn btn-primary btn-large text-normal"
                onClick={handleCalendly}
              >
                Schedule Call Now - It's Free
              </button> */}
              <BookStrategyBtnPrimary />
            </div>
          </div>
        </section>

        <FooterGlobal
          companyAddress={companyAddress}
          companyContactNumber={companyContactNumber}
          companyOverview={companyOverview}
          facebookLink={facebookLink}
          linkedinLink={linkedinLink}
          supportEmail={supportEmail}
          twiiterLink={twiiterLink}
        />
      </Layout>
    </>
  )
}

export default SEOPhilippines
